$(document).ready(function() {

    $('.header__burger').on('click', function(e) {
        e.preventDefault();
        $('nav.fullscreen').fadeIn();
        $('.header__close').on('click', function(e) {
            e.preventDefault();
            $('nav.fullscreen').fadeOut();
        });
    });

    $(window).on('scroll', function() {

        if ($(window).scrollTop() > 0) {
            $('header.header').addClass("sticky");
        } else {
            $('header.header').removeClass("sticky");
        }

    });

    // hero widget
    $('.hero__widget:not(.mobileactive)').on('click', function() {
        $("header").toggleClass("pen");
        $('.hero__property-search').fadeToggle();
        $('.hero__property-search').on('click', 'svg', function() {
            $('.hero__property-search').fadeOut();
        });
    });
    $('#Layer_1').on('click', function() {
        $("header").toggleClass("pen");
    });

    // Featured listing View button clicked
    $('.featuredlisting__slide').on('click', '.featuredlisting__view a', function(e) {
        e.preventDefault();
        $(this).parent().addClass('open');
        $('.featuredlisting__slide').on('click', '.featuredlisting__close', function(e) {
            e.preventDefault();
            $('.featuredlisting__view').removeClass('open');
        });
    });

    $('a[data-view]').on('click', function(e) {
        e.preventDefault();
        $('.results__view[data-view]').hide();
        $('.results__view[data-view=' + $(this).data('view') + ']').fadeIn();
        $('.results__controls a[data-view]').removeClass('active');
        $(this).addClass('active');
    });

    $('.results__controls').on('change', 'select[name=page_size]', function() {
        window.location = replaceQueryParam('page_size', $(this).find('option:selected').val(), window.location.search);
    });

    $('.results__controls').on('change', 'select[name=sortby]', function() {
        window.location = replaceQueryParam('sortby', $(this).find('option:selected').val(), window.location.search);
    });

    let logoGridOptions = {
        maxSlides: 5,
        minSlides: 5,
        slideWidth: 100,
    }

    $('.the-team__carousel').bxSlider({
        slideWidth: 480,
        minSlides: 2,
        maxSlides: 2,
        pager: false,
        infiniteLoop: false
    });

    // $('.logo-fader').bxSlider({
    //     ...logoGridOptions,
    //     auto: true,
    //     autoStart: true,
    //     // stopAutoOnClick: true,
    //     mode: 'fade',
    //     // autoHover: true,
    //     // slideMargin: 30,
    //     // moveSlides: 1,
    //     // maxSlides: 5,
    //     // minSlides: 5,
    //     slideWidth: 200,
    //     // slideMargin: 60,
    //     pager: false,
    //     controls: false
    // });

    if ($(window).width() <= 500) {
        let logoGridOptions = {
            maxSlides: 1,
            minSlides: 1,
            slideWidth: 200,
        }
    }

    let slidesPerPage = 5;
    if ($(window).width() <= 1550) {
        slidesPerPage = 4;
    }
    if ($(window).width() <= 1275) {
        slidesPerPage = 3;
    }
    if ($(window).width() <= 975) {
        slidesPerPage = 2;
    }
    if ($(window).width() <= 690) {
        slidesPerPage = 1;
    }

    $('.casestudies__carousel').bxSlider({
        maxSlides: slidesPerPage,
        slideWidth: 250,
        slideMargin: 30,
        moveSlides: 1,
        pager: false,
        controls: true
    });

    $('.case-studies-list__sectors').on('change', '#sector', function() {
        var opt = $(this).find('option:selected').val();
        $('.case-studies-list article').hide();
        if (opt) {
            $('.case-studies-list article[data-sector*=' + opt + ']').fadeIn();
        } else {
            $('.case-studies-list article').fadeIn();
        }
    });

    $('.view-all-clients').on('click', 'a', function(e) {
        e.preventDefault();
        $('.client-list').fadeIn();
        $([document.documentElement, document.body]).animate({
            scrollTop: ($('.client-list').offset().top - 75)
        }, 2000);
    });

    $('.dropdown-toggle + .dropdown-menu').on('click', 'button', function() {

        var checkbox = $(this).find('input[type=checkbox]');
        checkbox.prop("checked", !checkbox.prop("checked"));

        // Selecting 'top' dropdown type - deselect everything else
        // Selecting anything else - deselect 'top' dropdown type...
        if (checkbox.hasClass('top-dd-type')) {
            $('input.dd-type').prop('checked', false);
        } else {
            $('input.top-dd-type').prop('checked', false);
        }

        // Selecting 'top' dropdown tenure - deselect everything else
        // Selecting anything else - deselect 'top' dropdown tenure...
        if (checkbox.hasClass('top-dd-tenure')) {
            $('input.dd-tenure').prop('checked', false);
        } else {
            $('input.top-dd-tenure').prop('checked', false);
        }

        var parent = $(this).parents('.dropdown-menu'),
            button = parent.siblings('.dropdown-toggle'),
            input = parent.siblings('input[type=hidden]'),
            string = "",
            values = "";

        $(this).parents('.dropdown-menu').find('input:checked').each(function() {
            string += $(this).siblings('span').html() + ', ';
            values += $(this).val() + ',';
        });

        // Populate hidden field with values
        input.val(values.slice(0, -1));

        // Show selected options on button
        button.html(string.slice(0, -2));
    });

    // disable form submission on pressing enter
    var input = document.getElementById('location');
    if (input) {
        google.maps.event.addDomListener(input, 'keydown', function(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
            }
        });
    }

    if ($('.contact-map').length) {

        var lat = 52.627215,
            lng = 1.309006,
            point = { lat: lat, lng: lng },
            bounds = new google.maps.LatLngBounds(),
            map_style = [{
                    "featureType": "all",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                            "saturation": 36
                        },
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 40
                        }
                    ]
                },
                {
                    "featureType": "all",
                    "elementType": "labels.text.stroke",
                    "stylers": [{
                            "visibility": "on"
                        },
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "featureType": "all",
                    "elementType": "labels.icon",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.fill",
                    "stylers": [{
                            "color": "#000000"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.stroke",
                    "stylers": [{
                            "color": "#000000"
                        },
                        {
                            "lightness": 17
                        },
                        {
                            "weight": 1.2
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [{
                            "color": "#000000"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [{
                            "color": "#000000"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [{
                            "color": "#000000"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [{
                            "color": "#000000"
                        },
                        {
                            "lightness": 29
                        },
                        {
                            "weight": 0.2
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [{
                            "color": "#000000"
                        },
                        {
                            "lightness": 18
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [{
                            "color": "#000000"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [{
                            "color": "#000000"
                        },
                        {
                            "lightness": 19
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [{
                            "color": "#000000"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                }
            ];

        var results_map = new google.maps.Map(document.getElementById('gmap'), {
            center: point,
            maxZoom: 18,
            zoom: 16,
            scrollwheel: false,
            //styles: map_style
        });

        marker = new google.maps.Marker({
            position: { lat: parseFloat(lat), lng: parseFloat(lng) },
            map: results_map,
            title: 'Roche',
            icon: new google.maps.MarkerImage(
                '/images/logo.svg',
                null,
                null,
                null,
                new google.maps.Size(50, 50)
            )
        });

        bounds.extend(marker.position);

    }


    if ($('#location').length) {

        var defaultBounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(0.597543, 52.212199),
            new google.maps.LatLng(1.962272, 53.040590)
        );
        var input = document.getElementById('location');
        var options = {
            bounds: defaultBounds,
            componentRestrictions: { country: "uk" }
        };

        var autocomplete = new google.maps.places.Autocomplete(input, options);
        autocomplete.addListener('place_changed', function() {
            var place = autocomplete.getPlace();
            var latitude = place.geometry.location.lat();
            var longitude = place.geometry.location.lng();
            console.log(place.geometry);
            $('#latitude').val(latitude);
            $('#longitude').val(longitude);
        });
    }
});

function replaceQueryParam(param, newval, search) {
    var regex = new RegExp("([?;&])" + param + "[^&;]*[;&]?");
    var query = search.replace(regex, "$1").replace(/&$/, '');
    return (query.length > 2 ? query + "&" : "?") + (newval ? param + "=" + newval : '');
}